import React, { useState, useEffect } from 'react';
import data from '../assets/regions.json';

function RegionDropdown() {
    const [municipalities, setMunicipalities] = useState([]);

    useEffect(() => {
        // Flatten the JSON structure into a single list of municipalities
        const allMunicipalities = Object.values(data)
            .flatMap(region =>
                Object.values(region).flat() // Extract municipalities from each county
            );
        setMunicipalities(allMunicipalities);
    }, []);

    const handleMunicipalityChange = (event) => {
        console.log("Selected Municipality:", event.target.value);
    };

    return (
        <div>
            <label>Municipality:</label>
            <select onChange={handleMunicipalityChange}>
                <option value="">Select Municipality</option>
                {municipalities.map((municipality, index) => (
                    <option key={index} value={municipality}>
                        {municipality}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default RegionDropdown;
